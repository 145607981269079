<template>
  <div>
    <LoginComponent />
  </div>
</template>

<script>
import LoginComponent from "@/components/gate/LoginComponent";
export default {
  name: "LoginUserPage",
  components: { LoginComponent },
  created() {},
  methods: {}
};
</script>
