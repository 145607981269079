<template>
  <div>
    <v-card tile outlined class="rounded-xl">
      <v-card-text class="px-12">
        <v-alert type="error" text dense v-if="!!errorMessage">{{errorMessage}}</v-alert>
        <v-alert type="success" text dense v-if="!!signInMessage">{{signInMessage}}</v-alert>
        <v-form ref="form" v-model="valid">
          <v-text-field
            label="Your Registered Email"
            type="email"
            ref="email"
            v-model="email"
            :rules="commonValidationRules.emailIdRules"
            outlined
            dense
            class="mt-6 os-input os-input__outlined"
          />

          <v-text-field
            type="password"
            label="Password"
            ref="password"
            v-model="password"
            :rules="passwordRules"
            outlined
            dense
            class="mt-3 os-input os-input__outlined"
          />
          <div class="mb-4">
            <Button :loading="loading" :disabled="loading" @click="validate">Login</Button>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <p class="mt-3">
      <router-link :to="{name:'ForgotPassword'}">Forgot Password</router-link>
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { commonValidationRules } from "@/services/Utils";
import Button from "@/components/elements/Button.vue";
export default {
  name: "LoginComponent",
  components: { Button },
  computed: mapState({
    errorMessage: state => state.auth.errorMessage,
    role: state => state.auth.userAuth.role,
    appId() {
      return this.$route.params.appId;
    },
    signInMessage() {
      if (this.state && this.state.auth) return this.state.auth.signInMessage;
    },
    managerUrl() {
      return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_MANAGER_DOMAIN}?tickets`
    },
  }),
  data: () => ({
    loading: false,
    valid: true,
    email: null,
    commonValidationRules: commonValidationRules,
    password: null,
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length >= 6) || "Password must be at least 6 characters"
    ]
  }),
  watch: {
    role (val) {
      if (val) {
        this.$router.push({
          name: "Dashboard"
        }).catch(()=>{});
      }
    }
  },
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const appId = this.appId;
        const username = this.$refs.email.value;
        const password = this.$refs.password.value;
        try {
          await this.$store.dispatch("auth/login", { appId, username, password });
        }
        catch {}
        finally {
          this.loading = false
        }
      }
    }
  },
  created() {
    let msg = sessionStorage.interrupt_message;
    if (msg) {
      this.$store.dispatch("auth/prepare", msg);
      delete sessionStorage.interrupt_message;
    }
  }
};
</script>

<style lang="scss" scoped>
.titler {
  display: block;
  width: 100%;
}
</style>